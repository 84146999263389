<template>
  <div class="container">
    <div class="columns">
      <div class="column is-narrow"></div>
      <div class="column">
        <div class="columns">
          <div class="column is-one-third">
           <p class="harder">
              <strong>{{ config.i18n.address }}:&nbsp;</strong>
              {{ store.address }}
              <br />
              <strong>{{ config.i18n.city }}:&nbsp;</strong>
              {{ store.zip }} {{ store.city }}
              
            </p>
            <br />
            <p>
              <strong>{{ config.i18n.phone }}:&nbsp;</strong>
              <a class="a" :href="'tel:' + store.phone">{{ store.phone }}</a>
              <br />
              <strong>{{ config.i18n.email }}:&nbsp;</strong>
              <a class="a" :href="'mailto:' + store.email">{{ store.email }}</a>
              <br />
              <strong>{{ config.i18n.website }}:&nbsp;</strong>
              <a class="a" :href="'http://' + store.website" target="_blank">{{
                store.website
              }}</a>
              <br />
              <template v-if="store.open">
                <br/>
                <strong>{{ config.i18n.open }}</strong>
                <div class="redactor" v-html="store.open"></div>
              </template>
              <br />
                <a :href="'mailto:' + store.email" class="button">{{
              config.i18n.contact
            }}</a>
                <a :href="route" target="_blank" class="button">{{
              config.i18n.route
            }}</a>
            </p>
          </div>
          <div class="column is-two-thirds">
            <template v-if="storeOvens.length > 0">
                <div class="map__ovens">
                    <h4 class="h4 h4--inverted">{{ config.i18n.ovens }}</h4>
                    <ul>
                    <li v-for="oven in storeOvens" :key="oven.id">
                        <a class="a a--nude" :href="oven.url">{{ oven.name }}</a>
                    </li>
                    </ul>
                </div>
            </template>
            <template v-if="storeCategories.length > 0">
                <div class="map__ovens">
                    <h4 class="h4 h4--inverted">{{ config.i18n.ovenCategories }}</h4>
                    <ul>
                    <li v-for="cat in storeCategories" :key="cat.slug">
                        <a class="a a--nude" :href="cat.url" v-html="cat.name"></a>
                    </li>
                    </ul>
                </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["id", "embedded"],
  data() {
    return {};
  },
  computed: {
    store() {
      return this.$store.getters.selectedStore;
    },
    route() {
      const store = this.$store.getters.selectedStore;
      return (
        "https://www.google.com/maps/dir/?api=1&destination=" +
        store.lat +
        "," +
        store.lng +
        "&travelmode=driving"
      );
    },
    storeOvens() {
      const ovens = this.$store.getters.ovens;
      const store = this.$store.getters.selectedStore;
      return ovens.filter(oven => !!store.ovens.includes(oven.id));
    },
    storeCategories() {
      const categories = this.$store.getters.categories;
      const store = this.$store.getters.selectedStore;
      return categories.filter(category => !!store.categories.includes(category.id));
    },
    config() {
      return this.$store.getters.config;
    }
  },
  methods: {}
};
</script>
<style>
.span-pipe {
  padding-left: 10px;
  padding-right: 10px;
}

h3 {
  font-weight: bold;
}
</style>
